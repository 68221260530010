<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <FilterList
          @refetchListData="refetchListData"
          :filter="filter"
        />
      </v-col>
      <v-col cols="12">
        <table-data-list
          :key="key_table_list"
          :filter="filter"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableDataList from './TableDataList.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'component-location-oracle',
  components: {
    FilterList,
    TableDataList,
  },
  data() {
    return {
      key_table_list: 0,
      filter: {
        locationName: '',
        companyId: '',
        mouId: null,
      },
    };
  },
  methods: {
    refetchListData() {
      this.$router.replace({
        query: {
          page: 1,
        },
      });
      this.key_table_list += 1;
    },
  },
};
</script>
