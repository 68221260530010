<template>
  <section>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="items"
      item-key="id"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.action`]="{item}">
        <v-tooltip top v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="() => handleEdit(item)" >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.oracleData.LOCATION_ORACLE}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <DialogLocationFastlog
      ref="dialogLocationFastlog"
      @fetchData="fetchData"
    />
  </section>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

import DialogLocationFastlog from './Dialog/LocationFastlog.vue';

export default {
  name: 'table-list-location-oracle',
  props: {
    filter: {
      default: () => {},
      type: Object,
    },
  },
  components: {
    DialogLocationFastlog,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'No',
          value: 'no',
          class: 'white--text primary text-capitalize',
          width: '7%',
          sortable: false,
        },
        {
          text: this.$_strings.oracleData.ORACLE_ID,
          value: 'oracleLocationId',
          class: 'white--text primary text-capitalize',
          width: '10%',
        },
        {
          text: this.$_strings.oracleData.LOCATION_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          width: '20%',
        },
        {
          text: this.$_strings.oracleData.ADDRESS1,
          value: 'address1',
          class: 'white--text primary text-capitalize',
          width: '20%',
        },
        {
          text: this.$_strings.oracleData.ADDRESS2,
          value: 'address2',
          class: 'white--text primary text-capitalize',
          width: '20%',
        },
        {
          text: this.$_strings.oracleData.FASTLOG_LOCATION,
          value: 'l.name',
          class: 'white--text primary text-capitalize',
          width: '15%',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'action',
          align: 'center',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '4%',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading && this.filter.companyId) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    handleEdit(item) {
      this.$refs.dialogLocationFastlog.oracleLocationId = item.id;
      this.$refs.dialogLocationFastlog.mouId = this.filter.mouId;
      this.$refs.dialogLocationFastlog.dialog = true;
    },
    async fetchData() {
      const { companyId, locationName } = this.filter;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        name: locationName,
        sort: handleSortBy({ sortBy, sortDesc }),
      });
      try {
        this.isLoading = true;
        const result = await this.$_services.oracleData.getLocationOracle({ filter, companyId });
        this.items = result.data.contents.map((i, index) => ({
          ...i,
          no: (page - 1) * itemsPerPage + index + 1,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
