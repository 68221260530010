<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="750"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="3">
              <label>{{$_strings.oracleData.LOCATION_FASTLOG}}</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <common-auto-complete-items
                :type="mouId ? 'mouLocation' : null"
                searchName="locationName"
                item-value="locationId"
                item-text="locationName"
                height="10"
                v-model="fastlogLocationId"
                placeholder="Pilih Lokasi"
                dense
                outlined
                class="caption"
                clearable
                @updateItems="updateLocation"
                :optional="{
                  mouId,
                  mouBy: 'shipper',
                  isDelete: false
                }"
                :filter="filterLocationItems"
                :items="locationItems"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.oracleData.LOCATION_FASTLOG)]"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              outlined
              class="mr-2 ml-auto"
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="white--text"
              @click="submit"
              :loading="isLoading"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.oracleLocationId = null;
        this.mouId = null;
        this.fastlogLocationId = null;
        // this.$refs.form.reset();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      oracleLocationId: null,
      mouId: null,
      locationItems: [],
      filterLocationItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      fastlogLocationId: null,
    };
  },
  methods: {
    updateLocation(items) {
      this.locationItems = [...this.locationItems, ...items];
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        await this.$_services.oracleData.updateLocationOracleById({ oracleLocationId: this.oracleLocationId, fastlogLocationId: this.fastlogLocationId });
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchData');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
