<template>
  <section>
    <v-row align="center">
      <v-col cols="12" sm="4">
        <common-auto-complete-items
          type="masterShipper"
          searchName="companyName"
          item-value="id"
          item-text="companyName"
          v-model="filter.companyId"
          dense
          outlined
          class="caption"
          clearable
          hide-details
          placeholder="Pilih Shipper"
          @change="onChangeFilter"
          :filter="filterShipper"
          :items="shipperItems"
          :optional="{
            status: 'active',
            isGroup: true,
            isOracleIntegrated: true,
          }"
          @updateItems="updateShipperItems"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn small color="primary" @click="$emit('refetchListData', filter.companyId)">Submit</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn small color="primary" outlined @click="$refs.dialogImportCSV.dialog = true">Import CSV</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn small color="primary" @click="$emit('refetchListData', filter.companyId)">Sync</v-btn>
      </v-col>
      <v-col>
        <MasterDataCsvUpload
          :showButton="['STATUS']"
          :importTypeProp="$_strings.importType.ORACLE_LOCATION"
          :uploadCsvProp="uploadCsv"
          :downloadTemplateCsvProp="downloadTemplateCsv"
          dataTypeProp="ORACLE_LOCATION"
          @fetchList="$emit('refetchListData')"
        />
      </v-col>
      <DialogImportCSV
        ref="dialogImportCSV"
      />
    </v-row>
    <v-row justify="end">
      <v-col cols="12" sm="4">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          ref="search"
          @keyup.enter="search"
          placeholder="Cari Nama Lokasi/Lokasi Fastlog "
          v-model="locationName"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </section>
</template>

<script>

const MasterDataCsvUpload = () => import('../Common/MasterDataCsvUpload');
const DialogImportCSV = () => import('./Dialog/ImportCSV.vue');

export default {
  name: 'filter-list-location-oracle',
  components: {
    MasterDataCsvUpload,
    DialogImportCSV,
  },
  props: {
    filter: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      uploadingCsv: false,
      locationName: '',
      shipperItems: [],
      filterShipper: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    search() {
      this.filter.locationName = this.locationName;
      this.$emit('refetchListData');
    },
    onChangeFilter() {
      const findShipItem = this.shipperItems.find((s) => s.id === this.filter.companyId);
      if (findShipItem) {
        this.filter.mouId = findShipItem.mouId;
        return;
      }
      this.filter.mouId = null;
    },
    uploadCsv(formData) {
      return this.$_services.oracleData.uploadLocationOracle({ formData });
    },
    downloadTemplateCsv(type) {
      return this.$_services.oracleData.fetchCsvTemplateData(type);
    },
    updateShipperItems(items) {
      this.shipperItems = [...this.shipperItems, ...items];
    },
  },
};
</script>
